



























































import {Component, Vue} from "vue-property-decorator";
import Encryption from "@/encryption";
import ChatMessage from "@/components/chat/ChatMessage.vue";
import WorkspacePage from "@/components/layout/WorkspacePage.vue";


@Component({
  components: {WorkspacePage, ChatMessage}
})
export default class WorkspacePinnedMessages extends Vue {

  get pinnedMessages(){
    return this.$store.state.workspace.pinnedMessages;
  }

  get me() {
    return this.$store.state.me;
  }

  decrypt(message: string) {
    return Encryption.decrypt(message);
  }

  compareDates(first: Date, second: Date) {
    let firstDate = new Date(first);
    let secondDate = new Date(second);

    let secondBetweenTwoDate = Math.abs((firstDate.getTime() - secondDate.getTime()) / 1000);

    return secondBetweenTwoDate >= 1000;
  }


}
